import { StorableMfe, EventNames } from '@storable/mfe';

let mfe: StorableMfe | null = null;

export const initializeMFEProvider = async () => {
    mfe = new StorableMfe('Payments Portal');

    mfe.addEventListener(EventNames.APPLY_AUTH_TOKEN, (event: Event) => {
        const message = (event as unknown as CustomEvent).detail;
        const { token } = message.payload;
        window.localStorage.setItem('token', token);
        console.debug('Auth token has been recieved');
    });

    mfe.addEventListener(EventNames.APP_MESSAGE, (event: Event) => {
        const message = (event as unknown as CustomEvent).detail;
        const { type, data } = message.payload;
        if (type === 'facilityLevelReporting') {
            let isFacilityLevelReportingEnabled =
                data.isFacilityLevelReportingEnabled;
            const reportingFacilityIds = data.reportingFacilityIds || null;
            isFacilityLevelReportingEnabled =
                isFacilityLevelReportingEnabled &&
                Array.isArray(reportingFacilityIds) &&
                reportingFacilityIds.length > 0;
            window.localStorage.setItem(
                'isFacilityLevelReportingEnabled',
                JSON.stringify(isFacilityLevelReportingEnabled)
            );
            window.localStorage.setItem(
                'reportingFacilityIds',
                JSON.stringify(reportingFacilityIds)
            );
            console.debug('facilityLevelReporting event has been recieved');
        }
    });

    await mfe.start({
        title: 'Payments Portal',
        initialSize: { width: 500, height: 600 },
        minimumSize: { width: 300, height: 300 },
        fullscreen: false,
        allowResize: true,
    });
    console.debug('MFE provider started');
    try {
        mfe.requestAuthToken();
        console.debug('Requested token');
    } catch (error) {
        console.error('Error requesting token: ', error);
    }
};
